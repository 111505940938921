import { JSXElementConstructor } from "react"
import { FormProps } from "./types"
import { WorkdayAnniversaryGiftForm } from "./WorkdayAnniversaryGiftForm"

export const formRegistry = {
  WorkdayAnniversaryGiftForm,
}
export const formOptions: [string, string][] = Object.keys(formRegistry).map((id) => [id, formRegistry[id].title ?? id])

export * from "./types"
