import React, { ReactNode } from "react"
import { Bee } from "~/src/components/BeeKit"

export type BaseFormProps = {
  children: ReactNode
  disabled?: boolean
  title?: ReactNode
  onCancel: () => void
  onSubmit: () => void
}

export default function BaseForm({ children, disabled, title, onCancel, onSubmit }: BaseFormProps) {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="text-lg font-medium pb-4">{title}</h2>
      {children}
      <div className="flex flex-row gap-4 pt-4">
        <Bee.Button disabled={disabled} kind="secondary" type="button" onClick={onCancel}>
          Cancel
        </Bee.Button>
        <Bee.Button disabled={disabled} type="submit">
          Save
        </Bee.Button>
      </div>
    </form>
  )
}
