import React, { useCallback, useState } from "react"

import { Bee } from "~/src/components/BeeKit"
import BaseForm from "./BaseForm"
import { SelectPreferenceCollectorInput } from "./fields/SelectPreferenceCollectorInput"
import { FormProps } from "./types"

type WorkdayAnniversaryGiftConfig = {
  external_pg_id_map: {
    [year: number]: number
  }
}

type YearToGiftMap = Array<{ year: number | null; preferredGiftId: number | null }>

export function WorkdayAnniversaryGiftForm({
  initialData,
  isSubmitting,
  title,
  onCancel,
  onSubmit,
}: FormProps<WorkdayAnniversaryGiftConfig>) {
  const [yearToGiftMap, setYearToGiftMap] = useState<YearToGiftMap>(() => {
    const pgMap = initialData?.external_pg_id_map
    if (pgMap == null || Object.keys(pgMap).length === 0) {
      return [{ year: null, preferredGiftId: null }]
    } else {
      return Object.keys(pgMap).map((year) => ({ year: parseInt(year), preferredGiftId: pgMap[year] }))
    }
  })

  const addRow = useCallback(() => {
    setYearToGiftMap((prev) => [...prev, { year: null, preferredGiftId: null }])
  }, [])

  const updateYear = useCallback((index: number, year: number | null) => {
    setYearToGiftMap((prev) => prev.map((row, i) => (i === index ? { ...row, year } : row)))
  }, [])

  const updatePreferredGiftId = useCallback((index: number, preferredGiftId: number | null) => {
    setYearToGiftMap((prev) => prev.map((row, i) => (i === index ? { ...row, preferredGiftId } : row)))
  }, [])

  const removeRow = useCallback((index: number) => {
    setYearToGiftMap((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const handleSubmit = useCallback(() => {
    const data = {
      external_pg_id_map: yearToGiftMap.reduce(
        (acc, { year, preferredGiftId }) => {
          if (year != null && preferredGiftId != null) {
            acc[year] = preferredGiftId
          }
          return acc
        },
        {} as WorkdayAnniversaryGiftConfig["external_pg_id_map"]
      ),
    }

    onSubmit(data)
  }, [yearToGiftMap, onSubmit])

  return (
    <BaseForm disabled={isSubmitting} title={title} onCancel={onCancel} onSubmit={handleSubmit}>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr className="divide-x divide-gray-200">
            <th className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Year</th>
            <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Preferred Gift</th>
            <th className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">{/* Actions */}</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {yearToGiftMap.map(({ year, preferredGiftId }, index) => (
            <tr className="divide-x divide-gray-200" key={index}>
              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                <Bee.Input
                  className="w-full"
                  disabled={isSubmitting}
                  placeholder="Anniversary year"
                  type="number"
                  value={year ?? undefined}
                  onChange={(e) => updateYear(index, e.target.valueAsNumber)}
                />
              </td>
              <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                <SelectPreferenceCollectorInput
                  disabled={isSubmitting}
                  placeholder="Select a preferred gift"
                  value={preferredGiftId ?? undefined}
                  onChange={(newValue) => updatePreferredGiftId(index, newValue)}
                />
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 text-right sm:pr-0">
                <Bee.Button disabled={isSubmitting} kind="secondary" type="button" onClick={() => removeRow(index)}>
                  Remove
                </Bee.Button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td
              className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 text-right sm:pr-0 sm:pl-0"
              colSpan={3}
            >
              <Bee.Button disabled={isSubmitting} kind="secondary" type="button" onClick={addRow}>
                Add
              </Bee.Button>
            </td>
          </tr>
        </tfoot>
      </table>
    </BaseForm>
  )
}

WorkdayAnniversaryGiftForm.title = "Workday Anniversary Gift Form"
